/*------------------------------------------------------------------
[Master Stylesheet]

Project:    My Manu
Version:    0.1.0
Last change:    28/09/23
Assigned to:    Nistha Mehta
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Imports
2. Common Style Resets
3. Root Elements
4. Bootstrap Style Resets
5. Common Style
6. Toaster Alert
-------------------------------------------------------------------*/


/*-----------------------------------------------
1. Imports
-----------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/pretty-checkbox@3.0/dist/pretty-checkbox.min.css');
@import '~quill/dist/quill.snow.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/*-----------------------------------------------
2. Common Style Resets
-----------------------------------------------*/
html, body {box-sizing:border-box;height:100%;}
*, *:before, *:after {box-sizing:border-box;font-family: 'Nunito Sans', sans-serif;font-size:calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));}
body, h1, h2, h3, h4, h5, h6, p, ol, ul {margin: 0;padding: 0;font-weight: normal;word-break: keep-all;hyphens: auto;-webkit-hyphens: auto;-moz-hyphens: auto;-ms-hyphens: auto;}
ol, ul {list-style:none;}
img {max-width: 100%;height: auto;user-select: none;-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;-webkit-user-drag: none;}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {-webkit-text-fill-color: #000 !important;-webkit-box-shadow: 0 0 0px 40rem #F3F3F3 inset !important;}

.hidden{
  display: none;
}
/*-----------------------------------------------
3. Root Elements
-----------------------------------------------*/
:root {
  --primary-button-background-color: #000;
  --primary-button-text-color: #fff;

  --secondary-button-background-color: #fff;
  --secondary-button-text-color: #000;

  --blue-button-background-color: #0085FF;
  --blue-button-text-color: #FFFFFF;

  --link-button-background-color: #fff;
  --link-button-text-color: #0085FF;

  --input-background-color: #F3F3F3;
  --input-text-color: #000;

  --disbaled-button-background-color: #C1C1C1;

  --error-message-text-color: #FF0000;
}

/*-----------------------------------------------
4. Login, Forgot password, Reset password
-----------------------------------------------*/

.main-section {height:100%;min-height: 100vh;}
.main-section .inner-box {max-width: 420px;width:100%;display:flex;justify-content:center;align-items:center;height:100%;margin:auto;flex-direction:column;padding:1.25rem;}
.main-section .inner-box .login-box{width:100%;border: 1px solid #dbdfea;padding: 40px;border-radius:4px;}
.main-section .inner-box .login-box .title{margin-bottom: 0.5rem;font-weight: 700;color: #364a63;}
.main-section .inner-box .login-box .sub-title{color: #526484;font-size: calc(16px + (19 - 16) * ((100vw - 300px) / (1600 - 300)));line-height:22px;font-family: 'League Spartan', sans-serif;font-weight:300;}
.main-section .inner-box .form-control-box .label{font-family: 'League Spartan', sans-serif;font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));font-weight: 400;color: #344357;margin-bottom:0.5rem}
::placeholder{color:#B6C6E3 !important}
.main-section .inner-box .form-control-box .form-control{border:1px solid #DBDFEA;border-radius:5px;color:#212529;font-size:calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));padding: 9px 12px;}
.main-section .inner-box .form-control-box .form-group-box{margin-top:16px;}
.main-section .inner-box .form-control-box .primary-button{font-weight: 700;background-color: #EE0F37;padding:9px 0px}
.main-section .inner-box .form-control-box .forgot-code{color:#6576ff;font-size: calc(11px + (13 - 11) * ((100vw - 300px) / (1600 - 300)));font-weight: 700;margin-bottom:0.5rem}
.main-section .inner-box .form-control-box.form-control:focus{box-shadow:none;}
.main-section .inner-box .form-control-box .icon-btn{position:absolute;right:0;top:0;bottom:0;padding:10.5px 10px 10.5px 10px;border:none;background-color:transparent;}

@media (min-width: 576px){
    .main-section .inner-box {max-width: 520px !important;}
}

/*-----------------------------------------------
5. Toaster Alert
-----------------------------------------------*/

.toast-container .ngx-toastr{width:415px!important; background-color:#ffffff!important; border-radius:5px!important; box-shadow:4px 7px 8px #0000001A!important;background-position-x:15px!important;}
.toast-success {border:2px solid #5EC090!important; background-image:url('./assets/img/success-circle.svg')!important;}
.toast-error {border:2px solid #EC407A!important; background-image:url('./assets/img/error-circle.svg')!important;}
.toast-info {border:2px solid #5680E9!important; background-image:url('./assets/img/info-circle.svg')!important;}
.toast-warning {border:2px solid #EC407A!important; background-image:url('./assets/img/warning-circle.svg')!important;}
.toast-title {color:#414857!important; font-size:calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)))!important;}
.toast-message {font-size:calc(12px + (13 - 12) * ((100vw - 300px) / (1600 - 300)))!important; color: #8D929B!important;}
.toast-success .toast-progress {background-color:#5EC090!important;}
.toast-error .toast-progress {background-color:#EC407A!important;}
.toast-info .toast-progress {background-color:#5680E9!important;}
.toast-warning .toast-progress {background-color:#EC407A!important;}
.toast-success .toast-progress, .toast-warning .toast-progress, .toast-error .toast-progress, .toast-info .toast-progress {opacity:1!important; height:2px!important;}

@media screen and (max-width:575px) {
  .toast-container .ngx-toastr{width:280px!important;}
}

/*-----------------------------------------------
6. Heading
-----------------------------------------------*/

table {border-collapse: collapse;width: 100%;border:1px solid #dbdfea;background-color: #fff;}
th, td {border-bottom: 1px solid #dbdfea;padding: 1rem 0.5rem;text-align: left;}
th{color:#8094ae; font-weight:400;letter-spacing:0.6px;padding:0.5rem;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)))}
.form-check-input:checked{background-color: #EE0F37 !important;border-color: #EE0F37 !important;}
.form-check-input:focus {border-color: #fb8a9e !important;outline: 0;box-shadow: 0 0 0 0.25rem rgba(253, 29, 13, 0.25) !important;}
.btn-primary{color: #fff;background-color: #EE0F37;border-color: #EE0F37;}
.btn-primary:hover,
.btn-primary:active{color: #fff !important;background-color: #eb022c !important;border-color: #eb022c !important;}
.bg-blue{background-color:#1c2b46;color:#fff;}
.bg-blue:hover,
.bg-blue:active{background-color:#10264d !important;color:#fff !important;border-color:#10264d !important;}
.text-grey{color:#8094ae;}
.text-darkgrey{color:#526484;}
.form-control:focus{box-shadow:none !important;}
.h-35{height:35px}

/*-----------------------------------------------
7. User Tables Page
-----------------------------------------------*/

.inner-container{padding: 32px 36px;font-family: 'League Spartan', sans-serif;}
.inner-container .container-header{padding-bottom:28px}
.inner-container .container-header .main-heading{font-size:calc(24px + (30 - 24) * ((100vw - 300px) / (1600 - 300)));font-weight: 800;color: #344357;}
.inner-container .container-header .sub-heading{font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));font-weight: 300;color:#8094ae;}
.inner-container .container-header .add-btn{padding:5px 6px 0px 6px}
.inner-container .container-header .back-btn{font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));border-color: #dbdfea;color: #526484;font-weight: 700;background-color: white;}
.inner-container .main-container{margin-top:1rem}
.inner-container .main-container .card-inner{padding:1.5rem;}
.inner-container .main-container .card-inner .send-btn{border-radius:4px;font-weight:700;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)))}
.inner-container .add-container{border: 1px solid #dbdfea;background-color: #fff;border-radius: 4px;margin-top:1rem}
.inner-container .add-container .card-inner{padding:1.5rem;}
.inner-container .add-container .card-inner .preview-title{padding-bottom: 0.75rem;color: #8094ae;font-weight: 800;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));letter-spacing:0.11em}
.inner-container .add-container .card-inner .form-group .form-label{font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));font-family: 'League Spartan', sans-serif;font-weight: 400;color: #344357;margin-bottom: 0.5rem;}
.inner-container .add-container .card-inner .form-group .form-control{border: 1px solid #dbdfea;border-radius: 4px;height: calc(2.125rem + 2px);padding: 0.4375rem 1rem;}
.inner-container .add-container .card-inner .form-group .text-area{min-height:120px;}
.inner-container .add-container .card-inner .form-group .btn-lg{padding: 0.6875rem 1.5rem;font-size: calc(12px + (15 - 12) * ((100vw - 300px) / (1600 - 300)));line-height: 1.25rem;border-radius: 5px;font-weight:700;}
.inner-container .add-container .card-inner .form-group .form-check-input{height:1.5em;width:1.5em}
.inner-container .add-container .card-inner .form-group .custom-control-label{color: #526484;font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));}

.inner-container .main-container .user-table{margin-bottom:0px;min-width:1000px;}
.inner-container .main-container .user-table .dropdown .horiz-btn{color:#526484;padding: 0rem 1.5rem;}
.inner-container .main-container .user-table .dropdown .dropdown-menu{border-radius:4px;border: 1px solid #e5e9f2;box-shadow: 0 3px 12px 1px rgb(44 55 130 / 15%);}
.inner-container .main-container .user-table .dropdown .dropdown-menu .dropdown-item{padding: 0.325rem 1rem;}
.inner-container .main-container .user-table .dropdown .dropdown-menu .dropdown-item:hover{color: #6576ff;background: #f5f6fa;}
.inner-container .main-container .user-table .user-card{display: flex;align-items: center;}
.inner-container .main-container .user-table .user-card .user-avatar{height:40px;width:40px;display:flex;justify-content: center;align-items: center;border-radius:50%;background-color: #0085FF;color:#fff;font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));font-weight: 500;letter-spacing: 0.06em;}
.new-class{border-radius: 50%;height:40px;width:40px;}
.inner-container .main-container .user-table .user-card .user-info{margin-left:1rem;color: #8094ae;}
.inner-container .main-container .user-table .user-card .user-info .lead-text{font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));font-weight: 500;color: #364a63;display: block;line-height: 1.4;}
.inner-container .main-container .user-table .user-card .user-info .lead-list{font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300))) !important;}
.inner-container .main-container .user-table .login-type-text{font-weight: 500;color: #364a63;font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));}
.inner-container .main-container .user-table .table-details{font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)))}
.inner-container .main-container .user-table .form-checked{width: 1.125rem;height: 1.125rem;}
.inner-container .main-container .user-table .form-switch .form-check-input{width: 3rem;height:1.5rem}
.inner-container .main-container .search-bar{background-color: #fff;border: 1px solid #dbdfea; border-radius:4px; border-bottom:none;padding:20px}
.inner-container .main-container .border-end{border-right:1px solid #dbdfea}
.inner-container .main-container .user-table{border-right:none !important}
.inner-container .main-container .tr-height{height:66px;}

/*-----------------------------------------------
8. Modal
-----------------------------------------------*/

.custom-modal .modal-dialog {max-width:500px;margin:0 auto;}
.custom-modal .modal-dialog .modal-content { border-radius:5px; -webkit-border-radius:5px; -moz-border-radius:5px; -ms-border-radius:5px; -o-border-radius:5px; }
.custom-modal .modal-dialog .modal-content .modal-header {padding:16px 24px 16px 24px;}
.custom-modal .modal-dialog .modal-content .modal-header .modal-title {font-size:calc(18px + (20 - 18) * ((100vw - 300px) / (1600 - 300)));font-weight:800;color:#364a63;}
.custom-modal .modal-dialog .modal-content .modal-header .close-btn {border:none;background-color:transparent;padding:5px;line-height:15px;}
.custom-modal .modal-dialog .modal-content .modal-header .close-btn .icon {width:14px;height:14px;}
.custom-modal .modal-dialog .modal-content .modal-body {padding:24px 24px 24px 24px;}
.custom-modal .modal-dialog .modal-content .modal-body .text-area{border-radius:4px;min-height:100px;}
.custom-modal .modal-dialog .modal-content .modal-body .text {font-size:calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));font-weight:300;color:#526484;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .bottom-spacing {margin-bottom:20px;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .form-check{padding-left:0;min-height:2.5rem;display:flex;justify-content:space-between;align-items:center;flex-direction:row-reverse;border-bottom:1px solid #A1A1A1;padding-bottom:8px;margin-bottom:8px;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .form-check.no-border{border-bottom:none;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .form-check .form-check-input{float:right;width:34px;height:34px;background-color:#D9D9D9;border:none;box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.1);margin-top:0;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .form-check .form-check-input:checked{background-color:#D9D9D9;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .form-check .form-check-input:checked[type=radio]{background-size:28px;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .form-check .form-check-label{display:block;font-size:calc(15px + (17 - 15) * ((100vw - 300px) / (1600 - 300)));font-weight:400;color:#000;width:86%;}
.custom-modal .modal-dialog .modal-content .modal-footer {background-color:#e5e9f2;padding:16px 24px 16px 24px;display:flex;flex-direction:column;justify-content:center;}
.custom-modal .modal-dialog .modal-content .modal-footer .text-box {margin:20px 0 0 0;display:flex;justify-content:center;align-items:center;line-height: normal;}
.custom-modal .modal-dialog .modal-content .modal-footer .btn {max-width: max-content;min-width:70px;margin-left:8px;font-weight:700;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));border-radius:4px;}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .disabled-field{cursor:not-allowed}
.custom-modal .modal-dialog .modal-content .modal-body .custom-form .disabled-field input{color:#969696}
.custom-modal .modal-dialog .modal-content .modal-footer .save-btn{max-width:170px;font-weight:700;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));border-radius:4px;margin-left:0px}

/*-----------------------------------------------
9. View Details
-----------------------------------------------*/

.inner-container .container-header .username-heading{color: #EE0F37 !important;font-size:calc(24px + (30 - 24) * ((100vw - 300px) / (1600 - 300)));font-weight:400}
.inner-container .container-header .sub-heading{font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));font-weight:300;font-family: 'League Spartan', sans-serif;}
.inner-container .view-container{border: 1px solid #dbdfea;background-color: #fff;border-radius: 4px;margin-top:1rem}
.inner-container .view-container .nav-tabs-card{padding: 0 1.5rem;}
.inner-container .view-container .nav-tabs .nav-item{position: relative;margin-right: 2.25rem;}
.inner-container .view-container .nav-tabs .nav-link{border:none;color: #526484;font-weight:700;padding:1rem 0}
.inner-container .view-container .nav-tabs .nav-link:hover,
.inner-container .view-container .nav-tabs .nav-link:focus{border:none;color: #364a63;}
.inner-container .view-container .nav-tabs .nav-link.active{border:none;color: #EE0F37 !important;}
.inner-container .view-container .nav-tabs .nav-link.active:after{position: absolute;content: '';left: 0;right: 0;bottom:0;height: 3px;color: #EE0F37 !important;background: #EE0F37;opacity: 1;}
.inner-container .view-container .tab-content{margin-top: 1rem;}
.inner-container .view-container .personal-info-card{padding:1.5rem;}
.inner-container .view-container .card-heading{font-size:calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));font-weight: 800;line-height: 1.1;color: #364a63;margin-bottom:0.3rem}
.inner-container .view-container .sub-heading{font-weight: 300;line-height: 1.65;font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));color: #526484;font-family: 'League Spartan', sans-serif;margin-bottom:0.5rem}
.inner-container .view-container .personal-info-card .profile-label{color: #8094ae;font-family: 'League Spartan', sans-serif;font-weight:300;font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));padding:0.75rem}
.inner-container .view-container .personal-info-card .profile-value{color: #526484;font-family: 'League Spartan', sans-serif;font-weight:400;font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));padding:0.75rem;word-break:break-word;}
.inner-container .view-container .personal-info-card .additional-heading{color: #526484 !important;letter-spacing: 0.2em;font-size:11px;font-weight:700;margin-top:1rem;margin-bottom:0.5rem;}
.inner-container .view-container .personal-info-card .btn{max-width:70px;font-weight:700;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));border-radius:4px;margin-top:1rem}
.inner-container .view-container .personal-info-card textarea.form-control{min-height: 122px;margin-top:1rem}
.inner-container .view-container .admin-note{padding:1.5rem}
.inner-container .view-container .admin-note .card-inner{border-top: 1px solid #e5e9f2 !important;padding:1.5rem 0;}
.inner-container .view-container .admin-note .btn{color:#EE0F37;font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));font-weight:700}
.inner-container .view-container .admin-note .bq-note-text {padding: 1rem 1.25rem;background: #f5f6fa;border-radius: 4px;width:100%}
.inner-container .view-container .admin-note .bq-note-meta{font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));color: #8094ae;}
.inner-container .view-container .admin-note .bq-note-meta span{font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));color:#526484}

.inner-container .view-container .additional-details-card{border-left: 1px solid #dbdfea;min-height:calc(100vh - 250px)}
.inner-container .view-container .additional-details-card .card-inner{padding:1.5rem;border-bottom:1px solid #e5e9f2;}
.inner-container .view-container .additional-details-card .card-inner:last-child{border-bottom:0px !important}
.inner-container .view-container .additional-details-card .user-card-s2 {display: flex;flex-direction: column;text-align: center;padding: 0.5rem;}
.inner-container .view-container .additional-details-card .user-card {display: flex;align-items: center;justify-content: center;}
.inner-container .view-container .additional-details-card .user-card-s2 .user-avatar {margin: 0 auto;}
.inner-container .view-container .additional-details-card .user-avatar.lg {height: 80px;width: 80px;border-radius: 50%;display: flex;justify-content: center;align-items: center;color: #fff;}
.inner-container .view-container .additional-details-card .user-avatar span{font-size: calc(24px + (28 - 24) * ((100vw - 300px) / (1600 - 300)));font-weight: 400;font-weight: 500;letter-spacing: 0.06em;}
.inner-container .view-container .additional-details-card .user-card-s2 .user-info {margin: 1.25rem 0 0 !important;}
.inner-container .view-container .additional-details-card .user-card .user-info {color: #8094ae;}
.inner-container .view-container .additional-details-card .user-card-s2 .user-info .badge {margin-bottom: 1rem;}
.inner-container .view-container .additional-details-card .badge-outline-light {color: #b7c2d0;border-color: #d4dae3;}
.inner-container .view-container .additional-details-card .badge {position: relative;border: 1px solid currentColor;letter-spacing: 0.01em;vertical-align: middle;display: inline-flex;border-radius: 20px;}
.inner-container .view-container .additional-details-card .user-card-s2 .user-info .sub-text {justify-content: center;}
.inner-container .view-container .additional-details-card .user-info .lead-text,
.inner-container .view-container .additional-details-card .user-info .sub-text {display: flex;align-items: center;}
.inner-container .view-container .additional-details-card .sub-text {display: block;font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));color: #8094ae;}
.inner-container .view-container .additional-details-card .additional-details-card .card-inner{border-bottom: 1px solid #dbdfea;}
.inner-container .view-container .additional-details-card .additional-details-card .card-inner:last-child{border:none;}
.inner-container .view-container .additional-details-card .card-inner-sm{padding-top: 0.75rem !important;padding-bottom: 0.75rem !important;}
.inner-container .view-container .additional-details-card .btn-toolbar .btn{line-height:1}
.inner-container .view-container .additional-details-card .overline-title-alt {font-weight: 700;line-height: 1.2;letter-spacing: 0.15em;font-size: calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));color: #8094ae;text-transform: uppercase;}
.inner-container .view-container .additional-details-card .lead-text {font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));font-weight: 700;color: #364a63;display: block;}
.inner-container .view-container .additional-details-card .text-success {color: #1ee0ac !important;}
.inner-container .view-container .additional-details-card .sub-value{color: #526484;font-family: 'League Spartan', sans-serif;font-weight:300;font-size:calc(15px + (18 - 15) * ((100vw - 300px) / (1600 - 300)));}
.inner-container .view-container .additional-details-card .user-card-s2 .user-info .sub-text {justify-content: center;word-break: break-all;}
.readonly-input { background-color: #f5f6fa;}

.inner-container .main-container .user-table .user-card .service-image{height:60px;width:60px;display:flex;justify-content: center;align-items: center;border-radius:50%;color:#fff;font-size: calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));font-weight: 500;letter-spacing: 0.06em;}
.multiselect-dropdown .dropdown-btn{border: 1px solid #dbdfea !important;}
.multiselect-dropdown .dropdown-btn span{color:#B6C6E5 !important}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item{max-width:100% !important;border: 1px solid #EE0F37 !important;background: #EE0F37 !important;color:#fff !important}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item span{color:#fff !important}
.multiselect-item-checkbox input[type=checkbox]+div:before{border: 2px solid #EE0F37 !important;color: #EE0F37 !important;}
.multiselect-item-checkbox input[type=checkbox]:checked+div:before{background: #EE0F37 !important;}

.search-select.ng-select{border:1px solid #cdcdcd;border-radius:4px;padding:.4rem}.search-select .ng-dropdown-panel{background:#fff;box-shadow:0 0 1px #000}.search-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{padding:.2rem 1rem}.search-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{background:#f1f1f1}.inner-container .main-container .user-table .dropdown .single-table-entry{inset:-100px auto auto 0px!important}.disabled-button{background-color:#ccc;color:#000;cursor:not-allowed;pointer-events:none}.chat-image{width:100px;height:50px;max-width:100px;padding-bottom:50%;position:relative}.tel-input-error{color:#c0392b;font-size:calc(11px + (12 - 11) * ((100vw - 300px) / (1600 - 300)));position:absolute}.form-label sup{color:#e63946;top:-.3em}
.broadcast-wrap {display: flex;flex-direction: column;justify-content: space-between;height:100%}
.new-card{border: 1px solid #dbdfea;background: #FFFFFF;box-shadow: none;border-radius: 4px;padding: 20px;height: 31%;}
.dashboard-card-img{width:50px}
.dashboard-card-img.third{width:12%}
.dashboard-card-heading{color:#EE0F37; font-weight: 600; font-size: calc(24px + (28 - 24) * ((100vw - 300px) / (1600 - 300)));}
.dashboard-card-paragraph{font-weight: 600; font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));}
.disabled-button { background-color: #d5d5d5;  color: #000; cursor: not-allowed;}
.notify-small{display: none;}
.notify-large{display: flex;}

.pagination-data-container{display:flex;justify-content:space-between;align-items:center;margin-top:20px}.data-limit-dropdown{display:flex;align-items:center}.data-limit-dropdown label{margin-right:10px}.data-limit-dropdown select{font-size:calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)));padding:5px;border:1px solid #ccc;border-radius:4px;background-color:#fff;color:#333}

@media screen and (max-width: 1400px) and (min-width: 991px) and (max-height: 720px) {
  .logo-img{margin-left:1rem !important}
  .margin-end{margin-right:1rem !important}
  .sidebar.expanded{width:56px !important}
  .sidebar-head{padding:14px 16px !important;width:245px !important;}
  a.menu-link{padding: 0.625rem 0px 0.625rem 17px !important;font-size:13px !important}
  .sidebar{width:245px !important;}
  .sidebar-menu{width:245px !important;}
  .inner-container{padding:20px !important}
}

@media screen and (max-width: 767px) {
  .inner-container{padding:20px;}
  .inner-container .container-header{padding:0px;}
  .inner-container .main-container .search-bar{padding:12px;}
}

@media screen and (max-width: 600px) {
  .notify-small{display: flex;}
  .notify-large{display: none;}
  .pagination-data-container{flex-direction: column !important;justify-content: flex-end !important;align-items: end !important;}
}


/*-----------------------------------------------
10. Auto Complete
-----------------------------------------------*/
.autocomplete-field {width: 100%;position: relative;}
.autocomplete-field .mat-mdc-form-field-infix {min-height: 36px;padding-top: 6px !important;padding-bottom: 6px !important;}
.autocomplete-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
.autocomplete-field .mdc-line-ripple::before,
.autocomplete-field .mdc-line-ripple::after {
  border-bottom-width: 0;
}
.language-option{display: flex;align-items: center;gap: 10px;}
.language-option .flag-option{width: 36px;aspect-ratio: 1;max-width: 36px;}
.language-option .language-text-wrapper{display: flex;flex-direction: column;align-items: flex-start;margin: 2px auto;}
.language-option .local-wrapper{display: flex;align-items: center;gap: 6px;font-size: 14px; font-weight: 600;color: #000;}
.language-option .language{font-size: 12px;color: #969696;margin-top: -6px;}



.modal{z-index: 1000 !important;--bs-modal-zindex: 1000 !important;}
.modal-backdrop {z-index: 999;--bs-backdrop-zindex: 999 !important;}


.form-group .mat-mdc-form-field-subscript-wrapper { display: none!important;}
/* .custom-btn-width{width: 130px;} */